<i18n>
ru:
  cityShort: 'г. {city}'
  areaShort: 'р-н {area}'
  streetShort: 'ул. {street}'
  houseShort: 'д. {house}'
  corpusShort: 'к. {corpus}'
  buildingShort: 'стр. {building}'
  flatShort: 'кв./офис {flat}'
  doorwayShort: 'под. {doorway}'
  floorShort: 'эт. {floor}'
  intercomShort: 'код. {intercom}'
  officeShort: 'офис {office}'
  addressTypeShort: 'тип {type}'
  zipShort: 'зип {zip}'
ua:
  cityShort: 'м. {city}'
  areaShort: 'р-н {area}'
  streetShort: 'вул. {street}'
  houseShort: 'д. {house}'
  corpusShort: 'к. {corpus}'
  buildingShort: 'буд. {building}'
  flatShort: 'кв./офiс {flat}'
  doorwayShort: 'під. {doorway}'
  floorShort: 'пов. {floor}'
  intercomShort: 'код. {intercom}'
  officeShort: 'офіс {office}'
  addressTypeShort: 'тип {type}'
  zipShort: 'зіп {zip}'
us:
  cityShort: '{city} city'
  areaShort: '{area} area'
  streetShort: '{street} street'
  houseShort: '{house}'
  corpusShort: '{corpus} corpus'
  buildingShort: '{building} building'
  flatShort: '{flat} Apt./Office'
  doorwayShort: '{doorway} doorway'
  floorShort: '{floor} floor'
  intercomShort: 'intercom code: {intercom}'
  officeShort: '{office} office'
  addressTypeShort: '{type} type'
  zipShort: 'ZIP: {zip}'
</i18n>

<template>
  <span v-html="addressCompiled" />
</template>

<script setup lang="ts">
import type { OrderInfoAddress } from '~types/clientStore'

const { address } = defineProps<{ address: OrderInfoAddress }>()
const { translate } = useI18nSanitized()

const result: string[] = []
if (address) {
  if (address.CityName && address.CityName !== '')
    result.push(translate('addressDelivery.cityShort', { city: address.CityName }))
  if (
    address.GeoCity &&
    address.GeoCity !== '' &&
    address.CityName !== address.GeoCity
  )
    result.push(address.GeoCity)
  if (
    address.AreaName &&
    address.AreaName !== '' &&
    address.AreaName !== address.CityName
  )
    result.push(translate('addressDelivery.areaShort', { area: address.AreaName }))
  if (address.Street && address.Street !== '')
    result.push(translate('addressDelivery.streetShort', { street: address.Street }))
  else result.push(address.GeoDistrict)
  if (address.House && address.House !== '')
    result.push(translate('addressDelivery.houseShort', { house: address.House }))
  if (address.Corpus && address.Corpus !== '')
    result.push(translate('addressDelivery.corpusShort', { corpus: address.Corpus }))
  if (address.Building && address.Building !== '')
    result.push(
      translate('addressDelivery.buildingShort', { building: address.Building })
    )
  if (address.Doorway && address.Doorway !== '')
    result.push(
      translate('addressDelivery.doorwayShort', { doorway: address.Doorway })
    )
  if (address.Floor && address.Floor !== '')
    result.push(translate('addressDelivery.floorShort', { floor: address.Floor }))
  if (address.Apartment && address.Apartment !== '')
    result.push(translate('addressDelivery.flatShort', { flat: address.Apartment }))
  if (address.Office && address.Office !== '')
    result.push(translate('addressDelivery.officeShort', { office: address.Office }))
  if (address.Intercome && address.Intercome !== '')
    result.push(
      translate('addressDelivery.intercomShort', { intercom: address.Intercome })
    )
  if (address.AddressType && address.AddressType !== '')
    result.push(
      translate('addressDelivery.addressTypeShort', { type: address.AddressType })
    )
  if (address.ZipCode && address.ZipCode !== '')
    result.push(translate('addressDelivery.zipShort', { zip: address.ZipCode }))
} else {
  result.push('')
}
const addressCompiled: string = result.join(', ')
defineExpose({
  addressCompiled
})
</script>
